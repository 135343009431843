.thumbnails {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.thumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.frame {
  width: 100%;
  overflow: hidden;
}

.thumbnail img {
  width: 100%;
  height: auto;
  object-fit: cover;
  filter: brightness(80%);
}
