.thumbnails {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.thumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.frame {
  width: 100%;
  overflow: hidden;
}

.thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.box {
  img {
    transition: all 0.5s ease-out;
  }
/*   &:hover {
    img {
      transform: scale(1.1);
      transition: all 0.5s ease-out;
    }
  } */
}
