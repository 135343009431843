ol,
li {
  list-style: none;
  padding: 0;
  margin: 0;
  user-select: none;
}
a {
  transition-duration: 0.3s !important;
}
ol {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 26px;
  position: relative;
  cursor: pointer;
  height: 100%;
}

.title.selected {
  transform: scale(1.2);
}

.underline {
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background: black;
  position: absolute;
  bottom: -4px;
}

@media (max-width: 500px) {
  nav {
    width: 100%;
  }
  .underline {
    bottom: -4px;
    left: 1px;
  }
  .scroll {
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .scroll::-webkit-scrollbar {
    display: none;
  }
}
